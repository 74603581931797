@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800&display=swap");
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

.overflowHide {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.overflowHide::-webkit-scrollbar {
  display: none;
}
.overflowX {
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.overflowX::-webkit-scrollbar {
  display: none;
}
.subheading_underline::after {
  content: "";
  background-color: #d9d9d9;
  width: 100%;
  display: block;
  border: 1px solid #d9d9d9;
}
.toggled_btn {
  position: relative;
  /* background-color: #0eab44; */
  border: 1px solid #aaa;
  border-radius: 99px;
  transition: background-color 0.1s ease, border-color 0.2s ease;
  cursor: pointer;
  padding: 0.5rem 0;
}
.toggled_btn .thumb {
  background-color: #fff;
  border-radius: 99px;
  transform: translateX(0);
  transition: left 0.15s ease;
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
}
.toggled_btn.toggled .thumb {
  left: calc(50px - 25px);
}
@layer components {
  .bg-grad {
    @apply bg-gradient-to-b from-[#008E56] to-[#FFF62C];
  }
  .max-width1 {
    @apply max-w-7xl mx-auto p-4;
  }
  .text-pry {
    @apply text-[#FFF62C];
  }
  .text-sec {
    @apply text-[#008E56];
  }
  .bg-pry {
    @apply bg-[#FFF62C];
  }
  .bg-sec {
    @apply bg-[#008E56];
  }
  .bg-pry2 {
    @apply bg-[#E3FFDC];
  }
  .bg-sec2 {
    @apply bg-[#055429];
  }
  .text-pry2 {
    @apply text-[#E3FFDC];
  }
  .text-sec2 {
    @apply text-[#055429];
  }
}

/* elevenlabs */
elevenlabs-convai {
  transform: scale(0.75);
  transform-origin: bottom right;
  position: fixed !important;
  bottom: 10px !important;
  right: 10px !important;
  z-index: 9999 !important;
}

@media (max-width: 768px) {
  elevenlabs-convai {
      transform: scale(0.6);
  }
}
